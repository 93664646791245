import React from 'react'

interface Props {
  submit: boolean
  isEdited: boolean
  handleSave?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>
  handleCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const SavePanel: React.FC<Props> = ({ isEdited, handleSave, handleCancel, submit }) => {
  return (
        <div className="row justify-content-center">
        <div className="col-4">
          <button
            type="button"
            className="btn w-100 rounded-9 btn-color-secondary"
            disabled={!isEdited}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
        <div className="col-4">
          <button
            type={submit ? 'submit' : 'button'}
            className="btn w-100 rounded-9 btn-color-main"
            disabled={!isEdited}
            onClick={submit ? undefined : handleSave}
          >
            Save
          </button>
        </div>
      </div>
  )
}

export default SavePanel
