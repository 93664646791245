import React from 'react'
import Box from '@mui/material/Box'
import Tree from 'rc-tree'

import 'rc-tree/assets/index.css'
import './VisibilityTree.css'
import {
  type AllowDragType,
  type AllowDropType,
  type OnCheckType,
  type OnDropType,
  type TreeNode,
  type TreeNodeKey
} from './TreeTypes'

interface Props {
  treeData: TreeNode[]
  checkedIds: TreeNodeKey[]
  onDrop: OnDropType
  onCheck: OnCheckType
  allowDrag: AllowDragType
  allowDrop: AllowDropType
}

export function VisibilityTree({
  treeData,
  checkedIds,
  onDrop,
  onCheck,
  allowDrag,
  allowDrop
}: Props): JSX.Element {
  return (
    <div className="row justify-content-center mb-2">
      <Box
        sx={{
          maxWidth: 350,
          borderColor: 'white',
          borderWidth: '1px',
          borderStyle: 'groove',
          borderRadius: '1em'
        }}
      >
        <p>- Reorder by draging a node</p>
        <p>- Enable/Disable visibility with checkbox</p>
        <Tree
          checkable={true}
          defaultExpandAll={true}
          checkStrictly={true}
          virtual={false}
          showIcon={false}
          showLine={true}
          treeData={treeData}
          draggable={allowDrag}
          allowDrop={allowDrop}
          onDrop={onDrop}
          onCheck={onCheck}
          checkedKeys={checkedIds}
        />
      </Box>
    </div>
  )
}
