import React, { useState, useEffect, useCallback } from 'react'
import './Profile.css'
import { getUserData } from '../../api'
import { type UserProfile } from './ProfileInterface'
import { Inactive } from '../Reusable/Inactive/Inactive'
import { NavBar } from '../Reusable/NavigationBar/NavBar'
import TabSelection from './TabSelection/TabSelection'

const Profile: React.FC = () => {
  const [values, setValues] = useState<UserProfile>({
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    areaOfLaw: '',
    organization: '',
    topicsOfInterest: []
  })
  const [auth, setAuth] = useState(false)

  const fetchUserProfile = useCallback(() => {
    getUserData()
      .then((res) => {
        if (res.data.Status === 'Success') {
          setAuth(true)
          const userProfile = res.data.userProfile
          setValues({
            email: userProfile.email,
            areaOfLaw:
              userProfile.areaOfLaw !== null ? userProfile.areaOfLaw : '',
            username: userProfile.name,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            topicsOfInterest: userProfile.topicsOfInterest,
            organization: userProfile.organization
          })
        } else {
          setAuth(false)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  useEffect(() => {
    fetchUserProfile()
  }, [fetchUserProfile])

  return (
    <div className='row'>
      <NavBar />
      {auth ? <TabSelection userProfile={values} fetchUserProfile={fetchUserProfile}/> : <Inactive />}
    </div>
  )
}

export default Profile
