import type React from 'react'
import { useState, useCallback, useEffect } from 'react'
import { updateUserProfile } from '../../../api'
import {
  type MessageType,
  type ProfileViewProps,
  topics,
  type UserProfile
} from '../ProfileInterface'
import { defineNames } from '../../../utils/helpers'

export default function useEditUserProfile (
  userProfile: UserProfile,
  fetchUserProfile: () => void
): ProfileViewProps {
  const [values, setValues] = useState<UserProfile>({
    username: userProfile.username,
    email: userProfile.email,
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    organization: userProfile.organization,
    areaOfLaw: userProfile.areaOfLaw,
    topicsOfInterest: userProfile.topicsOfInterest
  })
  const [displayMessageType, setDisplayMessageType] = useState<MessageType>('')
  const [displayMessageText, setDisplayMessageText] = useState<string>('')
  const [info, setInfo] = useState('')
  const [initials, setInitials] = useState(
    defineNames(
      userProfile.username,
      userProfile.firstName,
      userProfile.lastName
    )
  )

  const [isEdited, setIsEdited] = useState(Boolean)
  const [selectedTopics, setSelectedTopics] = useState<string[]>(userProfile.topicsOfInterest ?? [])
  const [isAllSubjects, setAllSubjects] = useState(false)

  const handleTopicChange = useCallback((selectedTopic: string): void => {
    setSelectedTopics((prevSelectedTopics) => {
      if (prevSelectedTopics.includes(selectedTopic)) {
        setValues((prevValues) => ({
          ...prevValues,
          topicsOfInterest: prevValues.topicsOfInterest.filter(
            (topic) => topic !== selectedTopic
          )
        }))
        return prevSelectedTopics.filter((topic) => topic !== selectedTopic)
      } else {
        setValues((prevValues) => ({
          ...prevValues,
          topicsOfInterest: [...prevValues.topicsOfInterest, selectedTopic]
        }))
        return [...prevSelectedTopics, selectedTopic]
      }
    })
    setIsEdited(true)
    setDisplayMessageType('')
  }, [])

  const handleToggleAllSubjects = (): void => {
    if (isAllSubjects) {
      handleDeselectAll()
    } else {
      const allSubjects = topics
      setSelectedTopics(allSubjects)
      setValues((prevValues) => ({
        ...prevValues,
        topicsOfInterest: topics
      }))
    }
    setAllSubjects(!isAllSubjects)
    setDisplayMessageType('')
    setIsEdited(true)
  }

  const handleDeselectAll = useCallback(() => {
    setSelectedTopics([])
    setValues((prevValues) => ({
      ...prevValues,
      topicsOfInterest: []
    }))
  }, [])

  const handleChanges = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, parameter: string) => {
      const { value } = e.target
      setValues((prevValues) => ({
        ...prevValues,
        [parameter]: value
      }))
      setDisplayMessageType('')
      setIsEdited(true)
    },
    [setValues, setDisplayMessageType, setIsEdited]
  )

  const handleCancel = useCallback((
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    setInfo('')
    setDisplayMessageType('')
    setIsEdited(false)
    fetchUserProfile() // this should update userProfile and trigger useEffect
  }, [fetchUserProfile]
  )

  useEffect(() => {
    if (userProfile != null) {
      setValues(userProfile)
      setSelectedTopics(userProfile.topicsOfInterest ?? [])
      setInitials(
        defineNames(
          userProfile.username,
          userProfile.firstName,
          userProfile.lastName
        )
      )
    }
  }, [userProfile])

  const handleProfileSubmit = useCallback((
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    setInfo('')
    setDisplayMessageType('')
    const updatedValues = { ...values }
    if (updatedValues.firstName === '' || updatedValues.lastName === '') {
      setDisplayMessageType('error')
      setDisplayMessageText(
        'Invalid Input: First Name or Last Name can&apos;t be empty'
      )
      setIsEdited(false)
      return
    }

    updateUserProfile(updatedValues)
      .then((res) => {
        if (res.data.Status === 'Success') {
          setDisplayMessageType('success')
          setDisplayMessageText('')
          setIsEdited(false)
          setInitials(
            defineNames(
              userProfile.username,
              updatedValues.firstName,
              updatedValues.lastName
            )
          )
          fetchUserProfile()
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [userProfile.username, values, fetchUserProfile])

  useEffect(() => {
    // Initialize selectedTopics with topicsOfInterest from userProfile
    if (values.topicsOfInterest.length === topics.length) {
      setAllSubjects(true)
    }
  }, [userProfile, values.topicsOfInterest.length])

  return {
    profileValues: values,
    displayMessageType,
    displayMessageText,
    info,
    isEdited,
    userInitials: initials,
    selectedTopics,
    isAllSubjects,
    handleSubmit: handleProfileSubmit,
    handleCancel,
    handleProfileChange: handleChanges,
    handleTopicChange,
    handleToggle: handleToggleAllSubjects
  }
}
