import React, { useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import './GettingStarted.css'
import { useAction } from '../../../../contexts/useAction'
import { Grid } from '@mui/material'
import {
  type Feature,
  extensionFeaturesTest,
  INSTALL_EXTENSION,
  // pdfFeatures,
  FAQ,
  CASE_MEMO_URL,
  CASE_CHAT_URL
} from '../../documentConstants'
import { renderContent } from '../../DocumentRouter'
import { useNavigate } from 'react-router-dom'
import { DocumentNavigator } from '../../DocumentNavigator/NavigateDocument'
import { CANLII_SITE } from '../../../../utils/constants'

export function Overview (): JSX.Element {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, color: 'white' }}>
      <Toolbar />
      <div className="overview-container">
        <h2 className="overview-heading">Introduction to CanDoo AI</h2>
        <div className="overview-text">
          <p>
            Welcome to the CanDoo AI documentation. CanDoo AI is an advanced
            AI-powered research assistant tailored for legal professionals.
            CanDoo streamlines your legal workflow, helping you discover
            relevant cases faster, sift through them efficiently, and automate
            the process of report writing.
          </p>
        </div>
        <h2 className="overview-heading">Key Features</h2>
        <ul className="overview-text">
          <li>
            <strong>
              <a
                href={CASE_MEMO_URL}
                className="clicks"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Automated Case Memos{' '}
              </a>
            </strong>
            <p>
              Automate the creation of detailed research briefs with all
              relevant case facts and citations, allowing you to focus on your
              research query.
            </p>
          </li>
          <li>
            <strong>
              <a
                href={CASE_CHAT_URL}
                className="clicks"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Case-Chat{' '}
              </a>
            </strong>
            <p>
              For specific questions,{' '}
              <a
                href={CASE_CHAT_URL}
                className="clicks"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                case-chat
              </a>{' '}
              saves you time by providing direct answers and highlighting
              relevant document paragraphs.
            </p>
          </li>
          <li>
            <strong>CanDooPDF</strong>
            <p>
              Securely analyze any PDF with CanDoo’s private AI. Summarize
              client communications, search lengthy claims, convert meeting
              scribbles into reports, and perform many more operations on your
              data effortlessly.
            </p>
          </li>
        </ul>

        <h2 className="overview-heading">Benefits</h2>
        <ul className="overview-text">
          <li>
            <strong>Efficiency</strong>
            <p>
              Find better cases faster and improve the quality of your research.
            </p>
          </li>
          <li>
            <strong>Reliability</strong>
            <p>
              Trust the answers generated by CanDoo AI, supported by our
              patent-pending AI models that ensure every answer is fact-based
              with references.
            </p>
          </li>
          <li>
            <strong>Customization</strong>
            <p>
              Use CanDoo with your own data in a secure and private manner,
              tailored to your specific needs.
            </p>
          </li>
        </ul>

        <h2 className="overview-heading">Advantages of CanDoo AI</h2>
        <ul className="overview-text">
          <li>
            <strong>Legal-Specific AI</strong>
            <p>
              Our AI system is specifically tuned for legal researchers. Instead
              of creating a generic chatbot, we concentrate our language model
              on a select few, highly demanding legal research tasks, ensuring
              precision and relevance in every query.
            </p>
          </li>
          <li>
            <strong>Seamless Integration</strong>
            <p>
              Our plugin seamlessly integrates with{' '}
              <a
                href={CANLII_SITE}
                className="clicks"
                target="_blank"
                rel="noopener noreferrer"
              >
                CanLII
              </a>{' '}
              occupying the right side panel of your browser to provide valuable
              insights without disrupting your workflow. We’re developing a
              companion platform to integrate with multiple sources and work
              with many documents simultaneously.
            </p>
          </li>
          <li>
            <strong>Quick Verification of AI Outputs</strong>
            <p>
              We prioritize accuracy and reliability in our AI outputs. To
              minimize the risk of hallucinations, every output comes with
              references highlighting the original text sources. This feature
              enables you to verify the information quickly and confidently,
              ensuring the integrity of your research.
            </p>
          </li>
        </ul>
        <h2 className="overview-heading">Start now</h2>
        <div className="overview-text">
          {' '}
          <p className="documentation-para">
            Get started with CanDoo AI today through some of these useful
            resources:
          </p>
        </div>
      </div>

      <div className="feature-button-group mb-4">
        <FeatureButtons />
      </div>
      <DocumentNavigator prev={FAQ} next={INSTALL_EXTENSION} />
    </Box>
  )
}

const ButtonGroup = ({ features }: { features: Feature[] }): JSX.Element => {
  const { setActiveTitle } = useAction()
  const navigate = useNavigate()

  const handleItemClick = useCallback((item: string): void => {
    setActiveTitle(item)
    navigate(renderContent(item))
  }, [navigate, setActiveTitle])

  return (
    <Grid container spacing={2}>
      {features.map((feature, index) => {
        const IconComponent = feature.icon
        return (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <button
              className="overview-feature-buttons"
              onClick={() => {
                handleItemClick(feature.value)
              }}
            >
              <div className="feature-group">
                <p>
                  <IconComponent />
                </p>
                <p className="feature-heading">{feature.heading}</p>
                <p className="feature-text">{feature.description}</p>
              </div>
            </button>
          </Grid>
        )
      })}
    </Grid>
  )
}

const FeatureButtons = (): JSX.Element => {
  return (
    <a className="main-button-container">
      <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Grid item xs={8}>
          <ButtonGroup features={extensionFeaturesTest} />
        </Grid>
        {/* <Grid item xs={8}>
          <ButtonGroup features={pdfFeatures} />
        </Grid> */}
      </Grid>
    </a>
  )
}
