import React, { useState, useEffect, useCallback } from 'react'
import Logo from '../../Reusable/Logo/Logo'
import Footer from '../../Reusable/Footer/Footer'
import { ssoRegistration } from '../../../api'
import { type ssoRegister } from '../RegisterInterface'
import { handleErrors } from '../../../utils/handleResponse'
import { useAction } from '../../../contexts/useAction'
import { CANLII_SITE, TERMS } from '../../../utils/constants'
import { Loading } from '../../Reusable/Loading/Loading'

enum SsoRegistrationStatus {
  Idle,
  Loading
}

const SSORegister: React.FC = () => {
  const [values, setValues] = useState<ssoRegister>({
    email: '',
    role: 'lawyer'
  })
  const termsErrorMessage: string = "You haven't agreed to our terms and conditions."
  const [validationError, setValidationError] = useState<string>('')
  const [info, setInfo] = useState<string>('')
  const [checked, setChecked] = useState(false)
  const [registrationStatus, setRegistrationStatus] = useState(SsoRegistrationStatus.Idle)
  const { setLoginStatus } = useAction()

  const handleCheckboxChange = useCallback(async (): Promise<void> => {
    setChecked((prevState) => {
      const newState = !prevState
      if (newState) setValidationError('')
      return newState
    })
  }, [setChecked, setValidationError])

  const handleRoleChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedRole = e.target.value
    setValues({ ...values, role: selectedRole })
  }, [setValues, values])

  const handleBackendResponse = useCallback((res: any): void => {
    if (res.data.Status === 'Success') {
      setLoginStatus(true)
      window.location.href = CANLII_SITE
    }
  }, [setLoginStatus])

  const handleRegistration = useCallback(async (): Promise<void> => {
    try {
      if (!checked) {
        setValidationError(termsErrorMessage)
        return
      }

      setRegistrationStatus(SsoRegistrationStatus.Loading)
      const res = await ssoRegistration(values)
      setRegistrationStatus(SsoRegistrationStatus.Idle)

      handleBackendResponse(res)
    } catch (error) {
      setRegistrationStatus(SsoRegistrationStatus.Idle)
      setInfo(handleErrors(error))
    }
  },
  [
    checked, values, setValidationError, setInfo,
    setRegistrationStatus, handleBackendResponse
  ])

  const setInitialParameters = (searchParams: any): void => {
    const emailParam = searchParams.get('email')

    setValues((prevValues) => ({
      ...prevValues,
      email: emailParam !== null ? emailParam : prevValues.email
    }))
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    setInitialParameters(searchParams)
  }, [])

  return (
    <div>
      <Logo />
      <div className="container-ratio justify-content-center">
        <div className="margin-base login-container">
          <div className="row">
            <p className="help-text mb-3">
              Before completing your registration, take a moment to review the
              information provided below. Once you are satisfied that everything
              is correct, click the &rdquo;Complete Registration&rdquo; button.
            </p>
            {validationError.length > 0 && (
              <h4 className="fail-message text-center">{validationError}</h4>
            )}
            {info.length > 0 && (
              <h4 className="fail-message text-center">{info}</h4>
            )}
          </div>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="secondary box-style position-relative">
                {registrationStatus === SsoRegistrationStatus.Loading && <Loading overlay={true} />}

                <div className="main-comp">
                  <h4 className="heading-style">User Details Confirmation</h4>
                  <div className="mb-4">
                    <div className="row profile-label">
                      <div className="col-4 profile-style">
                        <label htmlFor="email">
                          <strong>Email Address</strong>
                        </label>
                      </div>
                      <div className="col-8">
                        <input
                          type="text"
                          placeholder="Enter Email"
                          value={values.email}
                          name="email"
                          className="form-control"
                          readOnly
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="row profile-label">
                      <div className="col-4 profile-style">
                        <label htmlFor="username">
                          <strong>
                            Role<span className="required">*</span>
                          </strong>
                        </label>
                      </div>
                      <div className="col-8">
                        <select
                          name="role"
                          className="btn w-100 btn-color-main"
                          onChange={handleRoleChange}
                          value={values.role}
                        >
                          <option value="lawyer">Lawyer</option>
                          <option value="law student">Law Student</option>
                          <option value="law faculty">Law Faculty</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row width-setter justify-content-center">
                    <div className="col-auto">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          checked={checked}
                          // eslint-disable-next-line @typescript-eslint/no-misused-promises
                          onChange={handleCheckboxChange}
                          className="form-check-input me-2"
                          id="termsCheckbox"
                          style={{ borderColor: 'black' }}
                        />
                        <label
                          className="form-check-label mb-0"
                          htmlFor="termsCheckbox"
                        >
                          <a
                            href={TERMS}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="forgot-pass"
                          >
                            <span className={checked ? 'bold-text' : ''}>
                              I agree to the terms and conditions
                            </span>
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>
                  <button
                    /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
                    onClick={handleRegistration}
                    className="btn w-100 rounded-9 btn-color-main"
                  >
                    Complete Registration
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '5em' }}>
        <Footer />
      </div>
    </div>
  )
}

export default SSORegister
