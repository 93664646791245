import React, { useCallback, useMemo } from 'react'
import './components/ColorCodes/color.css'
import './components/Shared/shared.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './components/Home/Home'
import Register from './components/Register/Register'
import Login from './components/Login/Login'
import NotFound from './components/NotFound/NotFound'
import Redirect from './components/Redirect/Redirect'
import ForgotPassword from './components/ForgotPassword/ForgotPassword'
import ResetPassword from './components/ResetPassword/ResetPassword'
import Profile from './components/Profile/Profile'
import SSORegister from './components/Register/SsoRegister/SsoRegister'
import { type Action } from './actions/Action'
import { ActionContext } from './contexts/ActionContext'
import { type ProfileInfo, type Status } from './models/Status'
import { useImmer } from 'use-immer'
import Greetings from './components/Reusable/Greetings/Greetings'
import CanDooDoc from './components/CanDooDoc/CanDooDoc'
import InstallExtension from './components/CanDooDoc/Documents/GettingStarted/InstallExtension'
import { Overview } from './components/CanDooDoc/Documents/GettingStarted/Overview'
import Memo from './components/CanDooDoc/Documents/ChromeExtension/Memo/Memo'
import CaseChat from './components/CanDooDoc/Documents/ChromeExtension/CaseChat'
import { PdfBulkQuestion, PdfProject, PdfProjectHistory } from './components/CanDooDoc/Documents/PdfChat/PdfChat'
import FAQ from './components/CanDooDoc/Documents/GettingStarted/FAQ'
import CanDooSearch from './components/CanDooDoc/Documents/ChromeExtension/CanDooSearch'
import CaseChatHistory from './components/CanDooDoc/Documents/ChromeExtension/CaseChatHistory'
import Welcome from './components/Welcome/Welcome'

function App (): JSX.Element {
  const action = useGlobalState()

  return (
    <ActionContext.Provider value={action}>
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/welcome' element={<Welcome />} />
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<Login />} />
        <Route path='/greet' element={<Greetings />} />
        <Route path='/redirect' element={<Redirect />} />
        <Route path='/profile/:tabId?' element={<Profile />} />
        <Route path='/login/find-user' element={<ForgotPassword />} />
        <Route path='/login/reset-password' element={<ResetPassword />} />
        <Route path='/sso/register' element={<SSORegister />} />
        <Route path="/video-document" element={<CanDooDoc><Overview /></CanDooDoc>} />
        <Route path="/document" element={<CanDooDoc><Overview /></CanDooDoc>} />
        <Route path="/document/install-extension" element={<CanDooDoc><InstallExtension /></CanDooDoc>} />\
        <Route path="/document/case-memo" element={<CanDooDoc><Memo /></CanDooDoc>} />
        <Route path="/document/case-chat" element={<CanDooDoc><CaseChat /></CanDooDoc>} />
        <Route path="/document/candoo-search" element={<CanDooDoc><CanDooSearch /></CanDooDoc>} />
        <Route path="/document/search-history" element={<CanDooDoc><CaseChatHistory /></CanDooDoc>} />
        <Route path="/document/pdf-project" element={<CanDooDoc><PdfProject /></CanDooDoc>} />
        <Route path="/document/pdf-history" element={<CanDooDoc><PdfProjectHistory /></CanDooDoc>} />
        <Route path="/document/bulk-question" element={<CanDooDoc><PdfBulkQuestion /></CanDooDoc>} />
        <Route path="/document/faq" element={<CanDooDoc><FAQ /></CanDooDoc>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
    </ActionContext.Provider>
  )
}

export default App

function initialStatus(): Status {
  return {
    isCanLiiOrigin: false,
    isLoggedIn: false,
    profile: null,
    activeTitle: 'Overview'
  }
}

function useGlobalState(): Action {
  const [status, setStatus] = useImmer<Status>(initialStatus())

  const setActiveTitle = useCallback((value: string) => {
    setStatus((prev) => {
      prev.activeTitle = value
    })
  }, [setStatus])

  const setCanLiiOrigin = useCallback((value: boolean) => {
    setStatus((prev: Status) => {
      prev.isCanLiiOrigin = value
    })
  }, [setStatus])

  const setLoginStatus = useCallback((value: boolean) => {
    setStatus((prev) => {
      prev.isLoggedIn = value
    })
  }, [setStatus])

  const setProfile = useCallback((value: ProfileInfo) => {
    setStatus((prev) => {
      prev.profile = value
    })
  }, [setStatus])

  const action = useMemo(() => {
    return {
      isCanLiiOrigin: status.isCanLiiOrigin,
      setCanLiiOrigin,

      isLoggedIn: status.isLoggedIn,
      setLoginStatus,

      profile: status.profile,
      setProfile,

      activeTitle: status.activeTitle,
      setActiveTitle
    } satisfies Action
  },
  [setActiveTitle, setCanLiiOrigin, setLoginStatus, setProfile,
    status.activeTitle, status.isCanLiiOrigin, status.isLoggedIn,
    status.profile
  ]
  )

  return action
}
