import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getUserData } from '../../api'
import './Redirect.css'
import useCountdown from '../Reusable/CountDown/useCountdown'
import LogoHome from '../LogoHome/LogoHome'
import { Inactive } from '../Reusable/Inactive/Inactive'

const Redirect: React.FC = () => {
  const [auth, setAuth] = useState(false)
  const [name, setName] = useState('')
  const { secondsLeft, startCountDown } = useCountdown()

  const formatName = (inputName: string): string => {
    return inputName.charAt(0).toUpperCase() + inputName.slice(1).toLowerCase()
  }

  useEffect(() => {
    getUserData()
      .then((res) => {
        if (res.data.Status === 'Success') {
          setAuth(true)
          setName(formatName(res.data.userProfile.name))
          startCountDown(5)
        } else {
          setAuth(false)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [startCountDown])

  return (
    <div>
      <div className="redirect-container">
        {auth
          ? (
          <div>
            <div className="justify-content-center">
              <LogoHome pageHeading="Successful User Registration" />
            </div>
            <div className="row justify-content-center mt-5">
              <div className="col-md-7 secondary">
                <div className="bg-white p-4 box-style">
                  <div className="align-items-center justify-content-center">
                    <h3 className="justify-items-center">Welcome {name},</h3>
                    <div className="redirect">
                      <h4>Thanks for registering with Formic AI</h4>
                      <p className="para-styling">
                        Redirecting to CanLii in {secondsLeft} seconds.{' '}
                      </p>
                      <p className="redirect-style">
                        Please <Link to="https://canlii.org">click here</Link>{' '}
                        if the browser doesn&apos;t automatically redirect you
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            )
          : (
          <div className="row justify-content-center">
            <div className="col-md-7">
              <Inactive />
            </div>
          </div>
            )}
      </div>
    </div>
  )
}

export default Redirect
