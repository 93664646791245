import React from 'react'
import { MemoSummary } from '../ICandooSettings'
import ToggleSetting from '../ToggleSetting/ToggleSetting'

interface Props {
  summary: MemoSummary
  onChange: (newValue: MemoSummary) => void
}

const Summary: React.FC<Props> = ({ summary, onChange }) => {
  const values = [
    {
      value: MemoSummary.Auto,
      text: 'Auto'
    },
    {
      value: MemoSummary.Long,
      text: 'Long'
    },
    {
      value: MemoSummary.Medium,
      text: 'Medium'
    },
    {
      value: MemoSummary.Short,
      text: 'Short'
    }
  ]

  return (
    <ToggleSetting
      name="Summary"
      defaultValue={summary}
      displayText={values}
      onChange={onChange}
    />
  )
}

export default Summary
