export enum MemoSummary {
  Auto = 'Auto',
  Short = 'Short',
  Medium = 'Medium',
  Long = 'Long',
}

export enum MemoFactsLength {
  Short = 'Short',
  Long = 'Long',
}

export enum MemoFactsPresentation {
  Paragraph = 'Paragraph',
  Bullet = 'Bullet',
}

export enum MemoTone {
  Personal = 'Personal',
  Client = 'Client',
  Supervisor = 'Supervisor',
}

export interface MemoOrdering {
  id: string
  children?: MemoOrdering[]
}

export interface MemoVisibility {
  memoSummaryVisible: boolean
  memoFactsVisible: boolean

  memoIssuesVisible: boolean
  memoIssuesRatioVisible: boolean
  memoIssuesAnalysisVisible: boolean
  memoIssuesOutcomeVisible: boolean

  memoCitationVisible: boolean

  memoCaseVisible: boolean
  memoCasePlaintiffVisible: boolean
  memoCaseDefendantVisible: boolean
  memoCaseJudgesVisible: boolean
  memoCaseCourtVisible: boolean
  memoCaseDecisionDateVisible: boolean

  memoArgumentsVisible: boolean
}

export interface ICandooSettings extends MemoVisibility {
  id: number
  name: string
  memoSummary: MemoSummary
  memoFactsLength: MemoFactsLength
  memoFactsPresentation: MemoFactsPresentation
  memoTone: MemoTone
  memoOrdering?: MemoOrdering[]
}

export enum SelectedSetting {
  Summary,
  FactsLength,
  FactsPresentation,
  Tone,
}

export interface SelectedSettingProps {
  selectedSetting: SelectedSetting
}
