import React from 'react'
import { MemoFactsLength } from '../ICandooSettings'
import ToggleSetting from '../ToggleSetting/ToggleSetting'

interface Props {
  factsLength: MemoFactsLength
  onChange: (newValue: MemoFactsLength) => void
}

const FactsLength: React.FC<Props> = ({ factsLength, onChange }) => {
  const values = [
    {
      value: MemoFactsLength.Long,
      text: 'Long'
    },
    {
      value: MemoFactsLength.Short,
      text: 'Short'
    }
  ]

  return (
    <ToggleSetting
      name="Facts Length"
      defaultValue={factsLength}
      displayText={values}
      onChange={onChange}
    />
  )
}

export default FactsLength
