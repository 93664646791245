import React from 'react'
import { type ProfileViewProps } from '../ProfileInterface'
import { UserDetails } from '../UserDetails/UserDetails'
import { UserPreferences } from '../UserPreferences/UserPreferences'
import { ProfileMessages } from '../Messages/Messages'
import SavePanel from '../SavePanel/SavePanel'

const UserProfile: React.FC<ProfileViewProps> = ({
  profileValues,
  displayMessageType,
  displayMessageText,
  handleProfileChange,
  info,
  handleSubmit,
  isEdited,
  userInitials,
  handleCancel,
  handleTopicChange,
  selectedTopics,
  handleToggle,
  isAllSubjects
}) => {
  return (
    <div>
      <div>
        <ProfileMessages
          header="Manage your info to make Candoo work better for you"
          displayMessageType={displayMessageType}
          displayMessageText={displayMessageText}
          info={info}
          isEdited={isEdited}
        />
        <div className="container-ratio justify-content-center">
          <div className="margin-base profile-container">
            <div className="row justify-content-center">
              <div className="col-md-14 secondary">
                <div className="p-4 box-style">
                  <form onSubmit={handleSubmit}>
                    <UserDetails
                      handleProfileChange={handleProfileChange}
                      profileValues={profileValues}
                    />
                    <UserPreferences
                      handleTopicChange={handleTopicChange}
                      selectedTopics={selectedTopics}
                      handleToggle={handleToggle}
                      isAllSubjects={isAllSubjects}
                    />
                    <SavePanel isEdited={isEdited} handleCancel={handleCancel} submit/>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfile
