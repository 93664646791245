import React from 'react'
import { type ProfileMessagesProps } from '../ProfileInterface'

export const ProfileMessages: React.FC<ProfileMessagesProps> = ({
  header,
  displayMessageType,
  displayMessageText,
  info,
  isEdited
}) => {
  return (
    <div className="row justify-content-center text-center">
      <h4
        className="sub-header text-center mb-3 mt-3"
        style={{ maxWidth: '100%' }}
      >
        {header}
      </h4>
      <div className="w-50">
        {displayMessageType === 'success' && (
          <h4 className="success-message text-center">Changes Saved</h4>
        )}
        {displayMessageType === 'error' && (
          <h4 className="fail-message text-center">
            {displayMessageText}
          </h4>
        )}
        {isEdited && (
          <h4 className="unstaged-message text-center">
            You have unsaved changes
          </h4>
        )}
        {info.length > 1 && (
          <h4 className="fail-message text-center">{info}</h4>
        )}
      </div>
    </div>
  )
}
