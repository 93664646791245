export function getApiUrl (): string {
  const ENVIRONMENT: string | undefined =
    process.env.REACT_APP_ENV ?? process.env.NODE_ENV

  if (
    ENVIRONMENT === 'development' ||
    ENVIRONMENT === 'dev' ||
    ENVIRONMENT === 'test'
  ) {
    return 'http://localhost:8081/api'
  }

  const currentDomain = window.location.hostname

  if (
    ENVIRONMENT === 'production' ||
    ENVIRONMENT === 'prod' ||
    ENVIRONMENT === 'staging'
  ) {
    return 'https://' + currentDomain + '/api'
  }

  throw new Error('Not implemented yet')
}

export function getHostNameUrl (): string {
  const ENVIRONMENT: string | undefined =
    process.env.REACT_APP_ENV ?? process.env.NODE_ENV

  if (
    ENVIRONMENT === 'development' ||
    ENVIRONMENT === 'dev' ||
    ENVIRONMENT === 'test'
  ) {
    const port = window.location.port != null && window.location.port !== ''
      ? `:${window.location.port}`
      : ''

    return `${window.location.protocol}//${window.location.hostname}${port}`
  }

  const currentDomain = window.location.hostname

  if (
    ENVIRONMENT === 'production' ||
    ENVIRONMENT === 'prod' ||
    ENVIRONMENT === 'staging'
  ) {
    return 'https://' + currentDomain
  }

  throw new Error('Not implemented yet')
}
