import React, { useState, useCallback } from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useAction } from '../../../contexts/useAction'
import { useNavigate } from 'react-router-dom'
import { renderContent } from '../DocumentRouter'

interface NestedListProps {
  title: string
  items: string[]
}
export default function NestedList ({
  title,
  items
}: NestedListProps): JSX.Element {
  const [open, setOpen] = useState(true)
  const { setActiveTitle, activeTitle } = useAction()
  const navigate = useNavigate()
  const handleClick = (): void => {
    setOpen(!open)
  }

  const handleItemClick = useCallback((item: string): void => {
    setActiveTitle(item)
    navigate(renderContent(item))
  }, [navigate, setActiveTitle])

  const commonStyles = {
    backgroundColor: '#011320',
    color: '#b6bec9 !important'
  }

  return (
    <List
      sx={{ width: '100%', maxWidth: 360 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item, index) => (
            <ListItemButton
              key={index}
              sx={{
                ...commonStyles,
                padding: 0,
                paddingLeft: '2em',
                fontSize: '1.5em',
                paddingRight: '1em'

              }}
              selected={activeTitle === item}
              onClick={() => {
                handleItemClick(item)
              }}
            >
              <ListItemText
                primary={item}
                sx={{
                  fontWeight: activeTitle === item ? 'bold' : 'normal',
                  color:
                  activeTitle === item
                    ? '#e0a966 !important'
                    : '#b6bec9 !important'
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </List>
  )
}
