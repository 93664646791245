import React, { useCallback } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

interface DisplayText<T> {
  value: T
  text: string
}

interface Props<T> {
  name: string
  defaultValue: T
  displayText: Array<DisplayText<T>>
  onChange: (newValue: T) => void
}

type ToggleEnum = string | number

export default function ToggleSetting<T extends ToggleEnum> ({
  name,
  defaultValue,
  displayText,
  onChange: handleChange
}: Props<T>): JSX.Element {
  const onChange = useCallback((event: React.MouseEvent<HTMLElement>, value: any) => {
    event.preventDefault()
    if (value != null) {
      handleChange(value)
    }
  }, [handleChange])

  const buttons = displayText.map(item => {
    return (<ToggleButton key={item.value} value={item.value as any}>{item.text}</ToggleButton>)
  })

  return (
        <div className="row justify-content-center text-center profile-label mb-4">
        <div className='col-4 profile-style'>
            <strong>{name}</strong>
        </div>

        <div className='col-8'>
            <ToggleButtonGroup
                color="primary"
                value={defaultValue}
                exclusive
                onChange={onChange}
                aria-label={name}
                className="toggle-btns"
                size="small"
                fullWidth={true}
            >
                {buttons}
            </ToggleButtonGroup>
        </div>
    </div>
  )
}
