import axios from 'axios'
import { getApiUrl } from '../utils/setEnvironment'
import { type ICandooSettings } from '../components/Profile/CandooSettings/ICandooSettings'

const API_URL: string = getApiUrl()

interface GetResponse {
  options: ICandooSettings[]
  selectedOptionId: number
}

interface UpdateResponse extends GetResponse {
  updatedOptionId: number
}

interface CreateResponse extends GetResponse {
  createdOptionId: number
}

interface DeleteResponse extends GetResponse {
  deletedOptionId: number
}

interface SelectResponse extends GetResponse {}

export async function getSelectedSettings(): Promise<ICandooSettings> {
  const response = await axios.get<GetResponse>(`${API_URL}/user-options`)

  return getSettings(response.data, response.data.selectedOptionId)
}

export async function updateSettings(
  data: ICandooSettings
): Promise<ICandooSettings> {
  const response = await axios.put<UpdateResponse>(
    `${API_URL}/user-options`,
    data
  )

  return getSettings(response.data, response.data.updatedOptionId)
}

export async function deleteSettings(id: number): Promise<ICandooSettings> {
  const response = await axios.delete<DeleteResponse>(
    `${API_URL}/user-options/${id}`
  )
  return getSettings(response.data, response.data.selectedOptionId)
}

export async function createSettings(): Promise<ICandooSettings> {
  const response = await axios.post<CreateResponse>(`${API_URL}/user-options`)
  return getSettings(response.data, response.data.createdOptionId)
}

export async function selectSettings(id: number): Promise<ICandooSettings> {
  const response = await axios.put<SelectResponse>(
    `${API_URL}/select-user-options/${id}`
  )
  return getSettings(response.data, response.data.selectedOptionId)
}

function getSettings(data: GetResponse, id: number): ICandooSettings {
  const option = data.options.find((x) => x.id === id)

  if (option == null) throw new Error('Settings not found')

  return option
}
