import React from 'react'
import { MemoTone } from '../ICandooSettings'
import ToggleSetting from '../ToggleSetting/ToggleSetting'

interface Props {
  tone: MemoTone
  onChange: (newValue: MemoTone) => void
}

const Tone: React.FC<Props> = ({ tone, onChange }) => {
  const values = [
    {
      value: MemoTone.Personal,
      text: 'Personal'
    },
    {
      value: MemoTone.Client,
      text: 'Client'
    },
    {
      value: MemoTone.Supervisor,
      text: 'Supervisor'
    }
  ]

  return (
    <ToggleSetting
      name="Tone"
      defaultValue={tone}
      displayText={values}
      onChange={onChange}
    />
  )
}

export default Tone
