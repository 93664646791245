import React from 'react'
import {
  MemoFactsLength,
  MemoFactsPresentation,
  MemoSummary,
  MemoTone,
  SelectedSetting
} from '../ICandooSettings'

interface Props {
  selectedSetting: SelectedSetting
  summary: MemoSummary
  factsLength: MemoFactsLength
  factsPresentation: MemoFactsPresentation
  tone: MemoTone
}

const PreviewSettings: React.FC<Props> = ({
  selectedSetting,
  summary,
  factsLength,
  factsPresentation,
  tone
}) => {
  let example: JSX.Element | null = null
  let header: string | null = null

  switch (selectedSetting) {
    case SelectedSetting.Summary:
      example = <SummaryExample summary={summary} />
      header = `${MemoSummary[summary]} Summary`
      break
    case SelectedSetting.FactsLength:
      example = (
        <FactsExample
          factsLength={factsLength}
          factsPresentation={factsPresentation}
        />
      )
      header = `${MemoFactsLength[factsLength]} ${MemoFactsPresentation[factsPresentation]} Facts`
      break
    case SelectedSetting.FactsPresentation:
      example = (
        <FactsExample
          factsLength={factsLength}
          factsPresentation={factsPresentation}
        />
      )
      header = `${MemoFactsLength[factsLength]} ${MemoFactsPresentation[factsPresentation]} Facts`
      break
    case SelectedSetting.Tone:
      example = <ToneExample tone={tone} />
      header = `${MemoTone[tone]} Tone`
      break
    default:
      break
  }

  const headerName = splitByCapital(header)

  return (
    <div className="example-container">
      <div className="secondary">
        <div className="p-4 box-style">
          <h4 className="heading-style">
            {'Preview'}
          </h4>
          <div className="container">
            <div className="row">
              <div className="col">
                <h4 className="heading-style">{headerName}</h4>
                <div className="row">{example}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function splitByCapital(word: string | null): string | null {
  if (word == null) return word
  return word.replace(/([a-z])([A-Z])/g, '$1 $2')
}

function SummaryExample({
  summary
}: {
  summary: MemoSummary
}): JSX.Element | null {
  let paragraphs
  switch (summary) {
    case MemoSummary.Auto:
      paragraphs = SUMMARY_AUTO
      break
    case MemoSummary.Long:
      paragraphs = SUMMARY_LONG
      break
    case MemoSummary.Medium:
      paragraphs = SUMMARY_MEDIUM
      break
    case MemoSummary.Short:
      paragraphs = SUMMARY_SHORT
      break
    default:
      return null
  }

  if (paragraphs == null) return <></>

  return <div><Paragraphs items={paragraphs} /></div>
}

interface FactsExampleProps {
  factsLength: MemoFactsLength
  factsPresentation: MemoFactsPresentation
}

function FactsExample({
  factsLength,
  factsPresentation
}: FactsExampleProps): JSX.Element | null {
  return (
    <div>
      {factsPresentation === MemoFactsPresentation.Bullet &&
      <FactListItems items={factsLength === MemoFactsLength.Short ? FACTS_SHORT_BULLET : FACTS_LONG_BULLET} />
      }
      {factsPresentation === MemoFactsPresentation.Paragraph &&
      <Paragraphs items={factsLength === MemoFactsLength.Short ? FACTS_SHORT_PARAGRAPH : FACTS_LONG_PARAGRAPH} />
      }
    </div>
  )
}

function FactListItems({ items }: { items: string[] }): JSX.Element {
  return (
    <ul>
      {items.map((x, i) => <li key={i}><span>{x}</span></li>)}
    </ul>
  )
}

function Paragraphs({ items }: { items: string[] }): JSX.Element {
  const lastIndex = items.length - 1
  return (
    <>
      {items.map((x: string, i: number) => <><p>{x}</p>{i !== lastIndex && <br />}</>)}
    </>
  )
}

interface ToneExampleProps {
  tone: MemoTone
}

function ToneExample({ tone }: ToneExampleProps): JSX.Element | null {
  let sampleText: string[] = []

  switch (tone) {
    case MemoTone.Personal:
      sampleText = TONE_PERSONAL
      break
    case MemoTone.Client:
      sampleText = TONE_CLIENT
      break
    case MemoTone.Supervisor:
      sampleText = TONE_SUPERVISOR
      break
    default:
      sampleText = TONE_PERSONAL
  }

  if (sampleText == null) return <></>

  return <div><Paragraphs items={sampleText} /></div>
}

export default PreviewSettings

const TONE_PERSONAL = [
  'This arbitration decision addresses a contract dispute between the Vancouver Police Board (VPB) and the Vancouver Police Union (VPU) for a collective bargaining agreement covering the period from January 1, 2020, onward. Negotiations and mediation failed to resolve outstanding issues, including wages, term, a new patrol premium, improved pregnancy and parental leave, increased per diem rates, and improved health benefits. The VPB proposed amendments to reduce gratuity plan costs, citing budgetary constraints imposed by the City of Vancouver. The Union sought wage increases to make Vancouver officers the highest paid in Canada, citing recruitment competition from the Surrey Police Service and rising inflation. The VPB argued for a more conservative approach, emphasizing the Citys budgetary limitations and proposing a 2.5% annual increase consistent with other city settlements. [1][2].',
  'The arbitrator awarded a three-year contract term (2020-2022), with wage increases of 3.0%, 2.5%, and 3.0% for each respective year. The maternity top-up was increased to 100% for 17 weeks. Per diem rates were increased to $95 per day. The arbitrator rejected the Unions proposals for unlimited extended health benefits, increased psychological services coverage, and inclusion of registered clinical counselors and social workers in the psychological services plan due to insufficient information and lack of demonstrated need. The VPBs proposal to amend the gratuity plan was also rejected, as it was deemed unlikely to have been achieved through bargaining. [3]'
]
const TONE_CLIENT = [
  'This arbitration decision resolves a disagreement between the Vancouver Police Board (the employer, VPB) and the Vancouver Police Union (representing the officers, VPU) about their contract covering the period from January 1, 2020, and onward.  They tried to negotiate a new contract and even used a mediator, but couldnt agree on several key points, including salaries, the length of the contract, extra pay for patrol officers, better pregnancy and parental leave, higher daily allowances when traveling for work (per diem rates), and better health benefits.  The Police Board wanted to reduce the costs of the retirement savings plan (gratuity plan) because the City of Vancouver had limited their budget. The Union wanted raises that would make Vancouver police officers the highest paid in Canada, arguing they needed to compete with the Surrey Police Service for new hires and keep up with the rising cost of living (inflation). The Police Board wanted smaller raises, pointing to the Citys budget limitations and suggesting a 2.5% yearly increase, similar to what other city employees received. [1][2] ',
  'The arbitrator (like a judge for this type of disagreement) decided on a three-year contract (2020-2022), with raises of 3.0%, 2.5%, and 3.0% for each year.  New mothers will now receive 100% of their salary for 17 weeks of maternity leave (a "top-up" to other benefits). The daily allowance when traveling for work increased to $95. The arbitrator didnt agree to the Unions requests for unlimited extended health benefits, more coverage for mental health services, or including registered clinical counselors and social workers in the mental health plan because the Union didnt provide enough information or proof that these changes were necessary. The Police Boards proposal to change the retirement savings plan was also rejected because the arbitrator felt it was unlikely the Union would have agreed to it during normal negotiations. [3]'
]
const TONE_SUPERVISOR = [
  'This arbitration award resolves a collective bargaining impasse between the Vancouver Police Board (VPB) and the Vancouver Police Union (VPU) for the 2020-2022 contract period.  Pre-arbitration negotiations and mediation proved unsuccessful in bridging the divide on key issues, notably wage increases, contract duration, the introduction of a patrol premium, enhancements to pregnancy and parental leave benefits, increased per diem rates, and improvements to the health benefits package.  The VPB, citing budgetary directives from the City of Vancouver, proposed amendments to contain escalating gratuity plan costs.  Conversely, the VPU, citing recruitment challenges posed by the Surrey Police Service and inflationary pressures, advanced a wage proposal that would position Vancouver police officers as the highest compensated in Canada.  The VPB advocated for a more fiscally prudent approach, referencing the Citys budgetary constraints and proposing a 2.5% annual wage increase, consistent with other municipal settlements. [1][2] ',
  'The arbitrator issued a three-year award covering 2020-2022, granting wage increases of 3.0%, 2.5%, and 3.0% respectively for each year of the term.  The maternity leave top-up provision was enhanced to provide 100% of salary for 17 weeks.  Per diem rates were increased to $95 per day.  Crucially, the arbitrator rejected several Union proposals, including unlimited extended health benefits, increased coverage for psychological services, and the inclusion of registered clinical counselors and social workers within the psychological services plan.  These rejections were predicated on a finding of insufficient supporting evidence and a lack of demonstrated need.  Similarly, the VPBs proposed amendments to the gratuity plan were rejected, with the arbitrator determining that such modifications were unlikely to have been achieved through collective bargaining. [3]'
]

const SUMMARY_AUTO = [
  'This arbitration decision addresses a contract dispute between the Vancouver Police Board (VPB) and the Vancouver Police Union (VPU) for a collective bargaining agreement covering the period from January 1, 2020, onward.  Negotiations and mediation failed to resolve outstanding issues, including wages, term, a new patrol premium, improved pregnancy and parental leave, increased per diem rates, and improved health benefits. The VPB proposed amendments to reduce gratuity plan costs, citing budgetary constraints imposed by the City of Vancouver. The Union sought wage increases to make Vancouver officers the highest paid in Canada, citing recruitment competition from the Surrey Police Service and rising inflation. The VPB argued for a more conservative approach, emphasizing the City\'s budgetary limitations and proposing a 2.5% annual increase consistent with other city settlements. [1][2]',
  'The arbitrator awarded a three-year contract term (2020-2022), with wage increases of 3.0%, 2.5%, and 3.0% for each respective year.  The maternity top-up was increased to 100% for 17 weeks. Per diem rates were increased to $95 per day. The arbitrator rejected the Union\'s proposals for unlimited extended health benefits, increased psychological services coverage, and inclusion of registered clinical counselors and social workers in the psychological services plan due to insufficient information and lack of demonstrated need. The VPB\'s proposal to amend the gratuity plan was also rejected, as it was deemed unlikely to have been achieved through bargaining. [3]'
]

const SUMMARY_LONG = [
  'The Vancouver Police Board (VPB) and the Vancouver Police Union (VPU) engaged in interest arbitration after failing to reach a new collective agreement.  The VPU, arguing that Vancouver officers should be the highest paid in Canada, sought a three-year term with substantial wage increases, a patrol premium, enhanced pregnancy and parental leave, higher per diem rates, and improved health benefits.  They cited rising inflation, competition from the Surrey Police Service, and the demanding nature of police work as justification.  The VPB, constrained by a city-imposed budget freeze, proposed a two-year term with more modest 2.5% annual wage increases, arguing that this was consistent with other city employee contracts and comparable police forces.  They also resisted significant benefit enhancements, citing cost concerns and lack of demonstrated need. [19]',
  'The arbitrator, guided by the Fire and Police Services Collective Bargaining Act, considered factors such as comparable employment terms, internal equity, community welfare, and the unique circumstances of the Vancouver Police Department.  Analyzing the arguments and applying the replication principle – aiming to mimic a hypothetical negotiated agreement – the arbitrator awarded a three-year term.  Wage increases of 3.0%, 2.5%, and 3.0% were granted for 2020, 2021, and 2022 respectively, reflecting recent settlements in BC, inflation, and the competitive recruitment landscape.  A 100% top-up for maternity leave for 17 weeks and increased per diem rates to $95 were also awarded, addressing the VPU\'s concerns about parental leave and travel expenses. [39]',
  'However, several VPU proposals were rejected.  The arbitrator found insufficient justification for the patrol premium, citing its high cost, limited prevalence in other agreements, and lack of demonstrated operational need.  Proposed enhancements to extended health benefits and the inclusion of additional professionals under the Psychological Services Plan were also denied due to insufficient information regarding their cost and impact.  Similarly, the VPB\'s proposal to amend the Gratuity Plan was rejected, as it was deemed unlikely to have been accepted by the VPU in negotiations.  The arbitrator\'s decision aimed to balance the interests of both parties, acknowledging the VPU\'s desire for improved compensation and benefits while respecting the VPB\'s budgetary constraints and the principles of fair and reasonable bargaining. [21]'
]

const SUMMARY_MEDIUM = [
  'The Vancouver Police Board (VPB) and the Vancouver Police Union (VPU) engaged in interest arbitration after failing to reach a new collective agreement following the expiration of their previous one.  Key issues included the agreement\'s term, wage increases, a patrol premium, pregnancy and parental leave benefits, per diem rates, and health benefits.  The VPB, facing a budget freeze, proposed a two-year term and 2.5% annual wage increases, while the VPU sought a three-year term, higher wages to become the highest paid in Canada, and improved benefits.  The arbitrator, guided by the Fire and Police Services Collective Bargaining Act, considered comparability with other police forces, internal equity, community welfare, and the City\'s budget.  Ultimately, a three-year term was awarded, with wage increases of 3.0%, 2.5%, and 3.0% for 2020, 2021, and 2022 respectively.  A 100% top-up for maternity leave for 17 weeks and increased per diem rates to $95.00 per day were also granted.  The VPU\'s requests for a patrol premium and further health benefit improvements, as well as the VPB\'s proposal regarding the Gratuity Plan, were rejected. [3]',
  'The arbitrator\'s decisions were heavily influenced by the replication principle, aiming to mimic a hypothetical negotiated agreement.  While acknowledging Toronto as a traditional comparator, the arbitrator prioritized recent settlements in smaller BC municipalities and the unique challenges faced by the Vancouver Police Department, including the emerging Surrey Police Service.  The City\'s budget was considered, but the absence of a formal inability-to-pay argument limited its impact.  The arbitrator granted some VPU proposals, reflecting likely negotiated outcomes, while rejecting others deemed unrealistic.  The three-year term was favored to avoid frequent arbitrations, and the wage increases, while making Vancouver officers among the highest paid nationally, were justified by inflation and specific local circumstances.  The enhanced maternity top-up and per diem rates were seen as reasonable compromises, while the patrol premium and health benefit changes were deemed unlikely concessions in bargaining.  The VPB\'s Gratuity Plan proposal was also rejected due to consistent Union opposition. [2]'
]

const SUMMARY_SHORT = [
  'The Vancouver Police Board (VPB or Employer) and the Vancouver Police Union (VPU or Union) were in a dispute regarding the terms of their collective agreement, the previous one having expired on December 31, 2019.  Negotiations and mediation failed to resolve outstanding issues, leading to interest arbitration under the Fire and Police Services Collective Bargaining Act. Key issues included the term of the new agreement, wage increases, a patrol premium, pregnancy and parental leave benefits, per diem rates, and health benefits. The VPB, constrained by a budget freeze imposed by the City of Vancouver, proposed a two-year term and 2.5% annual wage increases. The Union sought a three-year term, higher wage increases to make Vancouver officers the highest paid in Canada, and improvements to other benefits. The arbitrator awarded a three-year term, wage increases of 3.0%, 2.5%, and 3.0% for 2020, 2021, and 2022 respectively, a 100% top-up for maternity leave for 17 weeks, and increased per diem rates to $95.00 per day. The arbitrator rejected the Union\'s proposals for a patrol premium and further health benefit improvements, as well as the Employer\'s proposal regarding the Gratuity Plan. [3]'
]

const FACTS_SHORT_BULLET = [
  'The previous collective agreement had a one-year term and expired on December 31, 2019. [30]',
  'Negotiations and mediation were unsuccessful in resolving outstanding items. [6]',
  'The Union\'s remaining proposals included a new patrol premium, improved pregnancy and parental leave, increased per diem rates, and improvements to health benefits. [21]',
  'The Employer\'s sole proposal was to reduce costs associated with the gratuity plan benefit. [29]',
  'The City of Vancouver imposed budgetary constraints on the Police Board, impacting the Employer\'s ability to meet the Union\'s demands. [6]',
  'The Employer appealed the City’s budget freeze decision to the provincial director of police services. [15]',
  'The Union sought a three-year term for the new collective agreement, while the Employer proposed a two-year term. [30]',
  'The Union proposed wage increases of 4%, 3%, and 3% for each year of the new agreement, along with a cost of living adjustment (COLA) clause in the third year. The Employer proposed 2.5% increases for 2020 and 2021. [40][41]',
  'The Union argued for the highest pay in Canada, citing historical precedent and current market conditions. The Employer argued for maintaining competitive wages within budgetary constraints. [27]',
  'The Union highlighted the increased workload and challenges faced by Vancouver police officers during the pandemic. [14]',
  'The Union proposed a 5% patrol premium for members with over 10 years of seniority assigned to operations and working as part of a uniformed patrol team. [38]',
  'The Union proposed improvements to pregnancy and parental leave benefits, including a 100% top-up for the first 6 weeks and 93% for up to 52 weeks. [42][21]',
  'The Union proposed aligning per diem rates with those set by the Canada Revenue Agency. [43][27]',
  'The Union proposed improvements to extended health benefits, including an unlimited lifetime limit, increased coverage for the Psychological Services Plan, and inclusion of registered clinical counsellors and registered social workers. [21]',
  'The Employer proposed fixing gratuity values at the monetary rate at which they were earned. [29]',
  'The parties presented differing cost estimates and comparators for the various proposals. [44]',
  'The Arbitrator considered the statutory factors outlined in the Fire and Police Services Collective Bargaining Act, relevant case law, and the specific circumstances of the case. [4]'
]

const FACTS_LONG_BULLET = [
  'Collective Bargaining and Mediation: The previous one-year collective agreement expired on December 31, 2019. Negotiations for a renewal agreement, followed by mediation, failed to resolve any outstanding items. [4]',
  'Interest Arbitration: The Union applied for interest arbitration, and John B. Hall was appointed as the arbitrator. [4]',
  'Prior Award: A 2020 award by Arbitrator Mark Brown finalized the 2019 one-year agreement, addressing only salary with a 2.5% increase. [5]',
  'Negotiation Sessions: Several negotiation sessions took place in early 2021, but were unproductive due to the Employer\'s lack of a wage position and cancellations. The Employer\'s position on wages was constrained by the City of Vancouver\'s budget decisions. [6]',
  'Highest Paid Agreement: The Union asserts an agreement was reached during negotiations where the Employer agreed Vancouver police officers should be the highest paid in Canada. The Employer disputes the authority of the individual who made the statement. [7]',
  'Financial Constraints: The Employer\'s wage proposals were limited by budgetary constraints imposed by the City of Vancouver, a situation which the Employer appealed to the Director of Police Services. [6]',
  'Comparator Settlements: Other police forces in BC, notably Oak Bay and Delta, settled at 3.5% for 2020. Toronto, a key comparator, had a multi-year agreement with increases ranging from 1.75% to 2.5%.  Calgary and Edmonton historically had higher salaries due to Alberta\'s economic conditions. [8][9][10]',
  'Internal Comparators: The Employer reached agreements with other bargaining units, including Teamsters Local 31 and CUPE locals, with increases around 2.0%. The Vancouver Fire Fighters Association negotiated a 2.5% increase for 2020 and 2021. [11]',
  'Surrey Police Service: The establishment of the Surrey Police Service posed a potential threat to the VPD\'s ability to retain staff, as officers might transfer to the new force. [12][13][14]',
  'City of Vancouver Budget: The City of Vancouver\'s budget and financial situation, including its allocation to policing, were considered. The Employer\'s appeal to the Director of Police Services contained information about the City\'s budget decisions. [15]',
  'Inflation: Rising inflation rates in Vancouver and Canada were also taken into account. [16]',
  'Schedule "E" Principles: The Collective Agreement includes principles for negotiating benefit provisions, including comparing provisions with other City of Vancouver bargaining units and major municipal police departments in Canada. [17]',
  'The Collective Agreement between the Union and the Employer expired on December 31, 2019. [4]',
  'Negotiations and mediation were unsuccessful in resolving outstanding items. [6]',
  'Term [18]',
  'Wages [19]',
  'Patrol premium [20][21]',
  'Improved pregnancy and parental leave [22][21]',
  'Increased per diem rates [23][24][25]',
  'Improved health benefits [18]',
  'The Employer also dropped most of its proposals, except for amendments to reduce gratuity plan costs. [21]',
  'The previous one-year Collective Agreement (2019) was finalized by Arbitrator Mark Brown, awarding a 2.5% salary increase. [4]',
  'Negotiations for the new Collective Agreement began in January 2021 but were hampered by the Employer\'s lack of a wage position and cancellations of bargaining sessions. [26][4]',
  'The Employer proposed a 2.5% wage increase for 2020 and 2021, citing budgetary constraints imposed by the City of Vancouver. [11][6]',
  'The Union applied for mediation, but it was unsuccessful. [6]',
  'The Employer appealed the City\'s budget freeze decision to the provincial director of police services. [15]',
  'The Union cited the Employer\'s DPS Appeal document to support its proposals. [6]',
  'The parties presented arguments and evidence regarding the proposed changes to the Collective Agreement, including comparators from other police forces and the City of Vancouver. [27]',
  'The Arbitrator considered the factors enumerated in Section 4(6) of the Fire and Police Services Collective Bargaining Act, including terms of employment for comparable employees, internal consistency and equity, community interest and welfare, and other relevant factors. [28][17]',
  'The Arbitrator also considered the principle of reluctance by interest arbitrators to address matters beyond term and basic compensation, but found that this principle should not apply with the same rigor in this case due to the lack of meaningful bargaining. [17]'
]

const FACTS_SHORT_PARAGRAPH = [
  'Following the expiration of the one-year collective agreement on December 31, 2019, negotiations and mediation failed to resolve key issues between the Union and the Employer (Vancouver Police Board).  The Union sought a three-year agreement with 4%, 3%, and 3% annual wage increases (plus COLA), a new patrol premium, enhanced pregnancy/parental leave, increased per diems, and improved health benefits, arguing for top-tier compensation based on precedent, market conditions, and increased pandemic workload.  Conversely, the Employer, constrained by a City-imposed budget freeze (which they appealed), proposed a two-year agreement with 2.5% annual increases and aimed to reduce gratuity plan costs.  Disagreements on costing and comparators persisted, with the Arbitrator ultimately considering statutory factors, case law, and the specific circumstances to reach a decision. [6][5]'
]

const FACTS_LONG_PARAGRAPH = [
  'Following the expiration of the 2019 collective agreement, negotiations and mediation failed to produce a new agreement between the Vancouver Police Union and the Employer.  The Union subsequently applied for interest arbitration.  A key point of contention was wages, with the previous agreement, finalized through arbitration, providing a 2.5% increase.  The Employer\'s 2021 negotiations were hampered by budgetary constraints imposed by the City of Vancouver, leading to an appeal to the Director of Police Services.  The Union argued for higher wages, citing a purported agreement to make Vancouver police the highest paid in Canada, a claim disputed by the Employer.  Comparators, including settlements reached by other police forces and internal agreements with other City of Vancouver bargaining units, were considered.  Factors such as the establishment of the Surrey Police Service, inflation, and the City\'s overall financial situation also played a role. [6][8][7][2]',
  'The arbitration focused on key issues including wages, patrol premiums, parental leave, per diem rates, and health benefits.  The Employer primarily sought to reduce gratuity plan costs.  The Union highlighted the Employer\'s appeal to the Director of Police Services as supporting evidence for their proposals.  The arbitrator considered various factors mandated by the Fire and Police Services Collective Bargaining Act, including comparable employee terms, internal equity, and community welfare.  The limited progress in negotiations led the arbitrator to consider a wider range of issues than typically addressed in interest arbitration, which often focuses primarily on term and basic compensation. [4]'
]
