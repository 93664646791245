import React from 'react'
import { MemoFactsPresentation } from '../ICandooSettings'
import ToggleSetting from '../ToggleSetting/ToggleSetting'

interface Props {
  factsPresentation: MemoFactsPresentation
  onChange: (newValue: MemoFactsPresentation) => void
}

const FactsPresentation: React.FC<Props> = ({
  factsPresentation,
  onChange
}) => {
  const values = [
    {
      value: MemoFactsPresentation.Bullet,
      text: 'Bullet'
    },
    {
      value: MemoFactsPresentation.Paragraph,
      text: 'Paragraph'
    }
  ]

  return (
    <ToggleSetting
      name="Facts Presentation"
      defaultValue={factsPresentation}
      displayText={values}
      onChange={onChange}
    />
  )
}

export default FactsPresentation
