/* This file holds all the interfaces and variables
associated with userProfile worflow */

export interface UserProfile {
  email: string
  areaOfLaw: string
  username: string
  firstName: string
  lastName: string
  organization: string
  topicsOfInterest: string[]
}

export interface ProfileProps {
  userProfile: UserProfile
}

export const topics: string[] = [
  'Access to Information and Privacy',
  'Administrative Remedies',
  'Appeal',
  'Arbitration',
  'Bankruptcy and Insolvency',
  'Business',
  'Child Custody and Access',
  'Child Protection',
  'Citizenship and Immigration',
  'Commerce and Industry',
  'Constitution',
  'Contracts',
  'Creditors and Debtors',
  'Criminal or Statutory Infractions',
  'Damages',
  'Defences',
  'Environment',
  'Evidence',
  'Family',
  'Guardianship',
  'Health and Safety',
  'Indigenous Peoples',
  'Insurance',
  'Intellectual property',
  'International',
  'Interpretation',
  'Judicial Review',
  'Labour and Employment',
  'Motor Vehicles',
  'Municipalities',
  'Negligence',
  'Practice and Procedure',
  'Professions and Occupations',
  'Property and Trusts',
  'Public Administration',
  'Residential Tenancies',
  'Rights and Freedoms',
  'Search and Seizure',
  'Sentencing',
  'Support and Maintenance',
  'Taxation',
  'Torts',
  'Wills and Estates',
  'Young Offenders'
]

export type MessageType = '' | 'success' | 'error'

export interface ProfileViewProps {
  profileValues: {
    username: string
    email: string
    firstName: string
    lastName: string
    organization: string
    areaOfLaw: string
  }
  displayMessageType: MessageType
  displayMessageText: string
  info: string
  handleProfileChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    parameter: string
  ) => void
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  isEdited: boolean
  userInitials: string
  handleCancel: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  handleTopicChange: (selectedTopic: string) => void
  selectedTopics: string[]
  handleToggle: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  isAllSubjects: boolean
}

export interface ProfileMessagesProps {
  header: string
  displayMessageType: MessageType
  displayMessageText: string
  info: string
  isEdited: boolean
}

export interface UserDetailsProps {
  profileValues: {
    username: string
    email: string
    firstName: string
    lastName: string
    organization: string
    areaOfLaw: string
  }

  handleProfileChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    parameter: string
  ) => void
}

export interface UserPreferencesProps {
  handleTopicChange: (selectedTopic: string) => void
  selectedTopics: string[]
  handleToggle: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  isAllSubjects: boolean
}
