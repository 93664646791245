import React from 'react'
import Summary from './Summary/Summary'
import './CandooSettings.css'
import { type CandooSettingsResult } from './useCandooSettings'
import { ProfileMessages } from '../Messages/Messages'
import SavePanel from '../SavePanel/SavePanel'
import PreviewSettings from './Preview/PreviewSettings'
import FactsLength from './Facts/FactsLength'
import FactsPresentation from './Facts/FactsPresentation'
import Tone from './Tone/Tone'
import { VisibilityTree } from './VisibilityTree/VisibilityTree'

interface Props extends CandooSettingsResult {}

const CandooSettings: React.FC<Props> = ({
  memoSummary,
  memoFactsLength,
  memoFactsPresentation,
  memoTone,
  handleMemoSummaryChange,
  handleMemoFactsLengthChange,
  handleMemoFactsPresentationChange,
  handleMemoToneChange,
  handleSave,
  handleCancel,
  isEdited,
  header,
  displayMessageType,
  displayMessageText,
  info,
  selectedSetting,
  treeData,
  checkedIds,
  onDrop,
  onCheck,
  allowDrag,
  allowDrop
}): JSX.Element => {
  return (
    <>
      <ProfileMessages
        header={header}
        displayMessageType={displayMessageType}
        displayMessageText={displayMessageText}
        info={info}
        isEdited={isEdited}
      />

      <div className="container">
        <div className="row">
          <div className="col-xxl-6 col-12">
            <div className="settings-container">
              <div className="secondary">
                <div className="p-4 box-style">
                  <h4 className="heading-style">Memo Settings</h4>
                  <div className="container">
                    <Summary
                      summary={memoSummary}
                      onChange={handleMemoSummaryChange}
                    />
                    <FactsLength
                      factsLength={memoFactsLength}
                      onChange={handleMemoFactsLengthChange}
                    />
                    <FactsPresentation
                      factsPresentation={memoFactsPresentation}
                      onChange={handleMemoFactsPresentationChange}
                    />
                    <Tone tone={memoTone} onChange={handleMemoToneChange} />

                    <VisibilityTree
                      treeData={treeData}
                      checkedIds={checkedIds}
                      onDrop={onDrop}
                      onCheck={onCheck}
                      allowDrag={allowDrag}
                      allowDrop={allowDrop}
                      />

                    <SavePanel
                      isEdited={isEdited}
                      handleSave={handleSave}
                      handleCancel={handleCancel}
                      submit={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-6 col-12 mt-xxl-0 mt-2">
            <PreviewSettings
              selectedSetting={selectedSetting}
              summary={memoSummary}
              factsLength={memoFactsLength}
              factsPresentation={memoFactsPresentation}
              tone={memoTone}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CandooSettings
