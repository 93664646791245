import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { loginUser, type Credentials, ssoLogin } from '../../api'
import './Login.css'
import ShowPassword from '../Reusable/ShowPassword/ShowPassword'
import Logo from '../Reusable/Logo/Logo'
import Footer from '../Reusable/Footer/Footer'
import { type SsoProfile, processLogin } from '../../utils/Firebase'
import { handleErrors } from '../../utils/handleResponse'
import {
  invalidEmail,
  passwordResetSuccess,
  registrationComplete,
  ssoAccountExists,
  ssoAccountExistsMessage,
  ssoPopupError,
  ssoPopupErrorMessage,
  userInfoNotFound
} from '../../utils/messageConstants'
import Providers from '../Reusable/Providers/Providers'
import {
  CANLII_IDENTIFIER,
  REGISTRATION_COMPLETE
} from '../../utils/constants'
import { useAction } from '../../contexts/useAction'

export default function Login (): JSX.Element {
  const [values, setValues] = useState<Credentials>({
    email: '',
    password: ''
  })
  const { isCanLiiOrigin, setLoginStatus, setCanLiiOrigin } = useAction()
  const [info, setInfo] = useState('')
  const [message, setMessage] = useState('')
  const navigate = useNavigate()
  const [passwordType, setPasswordType] = useState('password')

  const handleDefaultLogin = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault()
    setInfo('')
    setMessage('')
    try {
      const res = await loginUser(values)
      handleBackendResponse(res)
    } catch (error) {
      setInfo(handleErrors(error))
    }
  }

  const handleBackendResponse = (res: any): void => {
    if (res.ok === true && isCanLiiOrigin) {
      setLoginStatus(true)
      navigate('/greet')
    } else if (res.ok === true) {
      setLoginStatus(true)
      navigate('/')
    } else if (res.status === 401) {
      setInfo('Bad Password. Please try again')
    } else if (res.status === 404) {
      setInfo('User Not Found')
    } else if (res.status === 500) {
      setInfo('Unknown Error. Please try again later.')
    }
  }

  const handleSsoBackendResponse = (
    res: any,
    email: string,
    name: string | undefined
  ): void => {
    if (res.ok === true && isCanLiiOrigin) {
      setLoginStatus(true)
      navigate('/greet')
    } else if (res.ok === true) {
      setLoginStatus(true)
      navigate('/')
    } else if (res.status === 404) {
      if (name != null) {
        const trimmedName: string = name.replace(/\s+/g, '').slice(0, 15)
        navigate(`/sso/register?email=${email}&username=${trimmedName}`)
      }
    } else if (res.status === 500) {
      setInfo('Unknown Error. Please try again later.')
    }
  }

  const handlePasswordTypeChange = (): void => {
    setPasswordType((prevType) =>
      prevType === 'password' ? 'text' : 'password'
    )
  }

  function handleSsoErrors (ssoError: undefined | string): void {
    if (ssoError === undefined) {
      setInfo(userInfoNotFound)
    } else if (ssoError === ssoAccountExists) {
      setInfo(ssoAccountExistsMessage)
    } else if (ssoError === ssoPopupError) {
      setInfo(ssoPopupErrorMessage)
    }
  }

  const handleSsoLogin = async (provider: string): Promise<void> => {
    const ssoProfile: SsoProfile | undefined | string = await processLogin(
      provider
    )
    if (ssoProfile === undefined || typeof ssoProfile === 'string') {
      handleSsoErrors(ssoProfile)
      return
    }

    let email, name
    if (typeof ssoProfile !== 'string') {
      email = ssoProfile.email
      name = ssoProfile.name
    }
    try {
      if (email == null) {
        setInfo(invalidEmail)
      } else {
        const res = await ssoLogin(email)
        handleSsoBackendResponse(res, email, name)
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const emailParam = searchParams.get('email')
    const ref = searchParams.get('ref')
    const internal = searchParams.get('internal')
    if (ref === CANLII_IDENTIFIER) setCanLiiOrigin(true)
    if (internal === REGISTRATION_COMPLETE) setMessage('registered')
    if (emailParam !== null) {
      setValues((prevValues) => ({
        ...prevValues,
        email: emailParam
      }))
      setMessage('success')
    }
  }, [setCanLiiOrigin])

  return (
    <div>
      <Logo />
      <div className="row justify-content-center">
        <div className="error-messages-container">
          {message === 'success' && (
            <h4 className="success-message text-center">
              {passwordResetSuccess}
            </h4>
          )}
          {message === 'registered' && (
            <h4 className="success-message text-center">
              {registrationComplete}
            </h4>
          )}
          {info.length > 1 && (
            <h4 className="fail-message text-center">{info}</h4>
          )}
        </div>
      </div>
      <div className="container-ratio justify-content-center">
        <div className="margin-base login-container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="secondary  box-style">
                <div className="main-comp">
                  <h4 className="heading-style">User Login</h4>
                  <form
                    /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
                    onSubmit={handleDefaultLogin}
                  >
                    <div className="row justify-content-center mb-3">
                      Login with Google or Microsoft
                    </div>
                    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                    <Providers handleAuth={handleSsoLogin} />
                    <div className="row justify-content-center">or</div>
                    <div className="mb-4">
                      <div className="row profile-label">
                        <div className="col-4 profile-style">
                          <label htmlFor="email">
                            <strong>
                              Email<span className="required">*</span>
                            </strong>
                          </label>
                        </div>
                        <div className="col-8">
                          <input
                            type="email"
                            placeholder="Enter Email"
                            value={values.email}
                            name="email"
                            autoComplete="username"
                            className="form-control"
                            required
                            onChange={(e) => {
                              setValues({ ...values, email: e.target.value })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="row profile-password-label">
                        <div className="col-4 profile-style">
                          <label
                            htmlFor="password"
                            className="register-password"
                          >
                            <strong>
                              Password<span className="required">*</span>
                            </strong>
                          </label>
                        </div>
                        <div className="col-8">
                          <input
                            type={passwordType}
                            placeholder="Enter Password"
                            name="password"
                            className="form-control"
                            autoComplete="current-password"
                            required
                            onChange={(e) => {
                              setValues({
                                ...values,
                                password: e.target.value
                              })
                            }}
                          />
                          <div>
                            <ShowPassword
                              onPasswordTypeChange={handlePasswordTypeChange}
                              passwordType={passwordType}
                              displayText="Show Password"
                            />
                            <Link to="/login/find-user" className="forgot-pass">
                              Forgot your password ?
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn w-100 rounded-9 btn-color-main"
                    >
                      Login
                    </button>

                    <div className="row justify-content-center">or</div>
                    <div className="mt-1 text-align">
                      <p className="mb-0">New to CanDoo AI? Join us!</p>
                    </div>
                    <Link
                      to="/register"
                      className="btn w-100 rounded-9 text-decoration-none mt-1 mb-0 btn-color-secondary"
                    >
                      Create Account
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '5em' }}>
        <Footer />
      </div>
    </div>
  )
}
