import {
  useState,
  useCallback,
  useEffect,
  type Dispatch,
  type SetStateAction
} from 'react'
import { type ProfileMessagesProps } from '../ProfileInterface'
import {
  type ICandooSettings,
  MemoFactsLength,
  MemoFactsPresentation,
  MemoSummary,
  MemoTone,
  SelectedSetting,
  type SelectedSettingProps
} from './ICandooSettings'
import {
  getSelectedSettings,
  updateSettings
} from '../../../api/candoo-settings'
import useVisibilityTree, {
  type VisibilityResult
} from './VisibilityTree/useVisibilityTree'
import { VisibilityMapper } from './visibilityMapper'

interface SummaryResult {
  memoSummary: MemoSummary
  handleMemoSummaryChange: (newValue: MemoSummary) => void
}

interface FactsLengthResult {
  memoFactsLength: MemoFactsLength
  handleMemoFactsLengthChange: (newValue: MemoFactsLength) => void
}

interface FactsPresentationResult {
  memoFactsPresentation: MemoFactsPresentation
  handleMemoFactsPresentationChange: (newValue: MemoFactsPresentation) => void
}

interface ToneResult {
  memoTone: MemoTone
  handleMemoToneChange: (newValue: MemoTone) => void
}

interface SubmitCancelButtons {
  handleSave: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>
  handleCancel: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}

type VisibilityTreeResult = Omit<
VisibilityResult,
'setTreeData' | 'setCheckedIds'
>

export type CandooSettingsResult = SummaryResult &
FactsLengthResult &
FactsPresentationResult &
ToneResult &
ProfileMessagesProps &
SubmitCancelButtons &
SelectedSettingProps &
VisibilityTreeResult

const visibilityMapper = new VisibilityMapper()

const defaultSettings: ICandooSettings = {
  id: -1,
  name: 'Profile-name',
  memoSummary: MemoSummary.Auto,
  memoFactsLength: MemoFactsLength.Long,
  memoFactsPresentation: MemoFactsPresentation.Bullet,
  memoTone: MemoTone.Personal,
  memoOrdering: visibilityMapper.getDefaultMemoOrdering(),
  ...visibilityMapper.getDefaultVisibility()
}

const nonDraggableKeys = visibilityMapper.getNonDraggableKeys()

export default function useCandooSettings(): CandooSettingsResult {
  const [loaded, setLoaded] = useState(false)

  const [message, setMessage] = useState<ProfileMessagesProps>({
    header: 'Candoo Chrome Extension Settings',
    displayMessageType: '',
    displayMessageText: '',
    info: '',
    isEdited: false
  })

  const handleEdited = useCallback(() => {
    setMessage((prev) => {
      return {
        ...prev,
        isEdited: true,
        displayMessageType: '',
        displayMessageText: ''
      }
    })
  }, [setMessage])

  const [selectedSetting, setSelectedSetting] = useState<SelectedSetting>(
    SelectedSetting.Summary
  )

  // server state
  const [originalSettings, setOriginalSettings] =
    useState<ICandooSettings>(defaultSettings)

  // dirty state:
  const [memoSummary, setMemoSummary, handleMemoSummaryChange] = useEnumState(
    defaultSettings.memoSummary,
    SelectedSetting.Summary,
    handleEdited,
    setSelectedSetting
  )
  const [memoFactsLength, setMemoFactsLength, handleMemoFactsLengthChange] =
    useEnumState(
      defaultSettings.memoFactsLength,
      SelectedSetting.FactsLength,
      handleEdited,
      setSelectedSetting
    )
  const [
    memoFactsPresentation,
    setMemoFactsPresentation,
    handleMemoFactsPresentationChange
  ] = useEnumState(
    defaultSettings.memoFactsPresentation,
    SelectedSetting.FactsPresentation,
    handleEdited,
    setSelectedSetting
  )
  const [memoTone, setMemoTone, handleMemoToneChange] = useEnumState(
    defaultSettings.memoTone,
    SelectedSetting.Tone,
    handleEdited,
    setSelectedSetting
  )

  const {
    setTreeData,
    setCheckedIds,
    checkedIds,
    treeData,
    ...visibilityTreeRest
  } = useVisibilityTree(
    visibilityMapper.toTreeNode(defaultSettings.memoOrdering),
    visibilityMapper.getAllKeys(),
    nonDraggableKeys,
    handleEdited
  )

  useEffect(() => {
    if (loaded) return

    setLoaded(true)

    getSelectedSettings()
      .then((res: ICandooSettings) => {
        // save server state
        setOriginalSettings(res)

        // save to dirty state
        setMemoSummary(res.memoSummary)
        setMemoFactsLength(res.memoFactsLength)
        setMemoFactsPresentation(res.memoFactsPresentation)
        setMemoTone(res.memoTone)

        const newCheckedIds = visibilityMapper.toChecked(res, checkedIds)
        setCheckedIds(newCheckedIds)

        const newTreeData = visibilityMapper.toTreeNode(res.memoOrdering)
        setTreeData(newTreeData)
      })
      .catch((error) => {
        console.log(error)

        setMessage((prev) => {
          return {
            ...prev,
            isEdited: false,
            displayMessageType: 'error',
            displayMessageText:
              'Error: failed to load. Please reload the page.'
          }
        })
      })
  }, [
    loaded,
    setCheckedIds,
    setMemoFactsLength,
    setMemoFactsPresentation,
    setMemoSummary,
    setMemoTone,
    checkedIds,
    setTreeData
  ])

  const handleSave = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      try {
        event.preventDefault()

        const visibilityFields = visibilityMapper.fromChecked(checkedIds)

        const newTreeData = visibilityMapper.fromTreeNodes(treeData)

        await updateSettings({
          id: originalSettings.id,
          name: originalSettings.name,
          memoSummary,
          memoFactsLength,
          memoFactsPresentation,
          memoTone,
          ...visibilityFields,
          memoOrdering: newTreeData
        })

        setOriginalSettings({
          id: originalSettings.id,
          name: originalSettings.name,
          memoSummary,
          memoFactsLength,
          memoFactsPresentation,
          memoTone,
          ...visibilityFields,
          memoOrdering: newTreeData
        })

        setMessage((prev) => {
          return {
            ...prev,
            isEdited: false,
            displayMessageType: 'success',
            displayMessageText: ''
          }
        })
      } catch (error) {
        setMessage((prev) => {
          return {
            ...prev,
            isEdited: false,
            displayMessageType: 'error',
            displayMessageText:
              'Error: failed to save changes. Please try again'
          }
        })
      }
    },
    [
      checkedIds,
      treeData,
      originalSettings.id,
      originalSettings.name,
      memoSummary,
      memoFactsLength,
      memoFactsPresentation,
      memoTone
    ]
  )

  const handleCancel = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault()

      // reset to original value
      setMemoSummary(originalSettings.memoSummary)
      setMemoFactsLength(originalSettings.memoFactsLength)
      setMemoFactsPresentation(originalSettings.memoFactsPresentation)
      setMemoTone(originalSettings.memoTone)
      setSelectedSetting(SelectedSetting.Summary)

      const newCheckedIds = visibilityMapper.toChecked(
        originalSettings,
        checkedIds
      )
      setCheckedIds(newCheckedIds)

      const newTreeData = visibilityMapper.toTreeNode(
        originalSettings.memoOrdering
      )
      setTreeData(newTreeData)

      setMessage((prev) => {
        return {
          ...prev,
          isEdited: false,
          displayMessageType: '',
          displayMessageText: ''
        }
      })
    },
    [
      setMemoSummary,
      originalSettings,
      setMemoFactsLength,
      setMemoFactsPresentation,
      setMemoTone,
      checkedIds,
      setCheckedIds,
      setTreeData
    ]
  )

  return {
    memoSummary,
    memoFactsLength,
    memoFactsPresentation,
    memoTone,
    handleMemoSummaryChange,
    handleMemoFactsLengthChange,
    handleMemoFactsPresentationChange,
    handleMemoToneChange,
    handleSave,
    handleCancel,
    ...message,
    selectedSetting,
    checkedIds,
    treeData,
    ...visibilityTreeRest
  }
}

function useEnumState<S>(
  value: S,
  selected: SelectedSetting,
  handleEdited: () => void,
  setSelectedSetting: Dispatch<SetStateAction<SelectedSetting>>
): [S, Dispatch<SetStateAction<S>>, (value: S) => void] {
  const [state, setState] = useState<S>(value)

  const handleChange = useCallback(
    (value: S) => {
      setState(value)
      setSelectedSetting(selected)
      handleEdited()
    },
    [handleEdited, selected, setSelectedSetting]
  )

  return [state, setState, handleChange]
}
