import type React from 'react'
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState
} from 'react'
import { type DataNode } from 'rc-tree/lib/interface'
import {
  type TreeProps,
  type AllowDropOptions,
  type CheckInfo
} from 'rc-tree/lib/Tree'
import {
  type AllowDragType,
  type AllowDropType,
  type OnCheckType,
  type OnDropInfo,
  type OnDropType,
  type TreeNodeKey,
  type TreeNode
} from './TreeTypes'

export interface VisibilityResult {
  treeData: TreeNode[]
  setTreeData: Dispatch<SetStateAction<TreeNode[]>>

  checkedIds: TreeNodeKey[]
  setCheckedIds: Dispatch<SetStateAction<TreeNodeKey[]>>

  onDrop: OnDropType
  onCheck: OnCheckType
  allowDrag: AllowDragType
  allowDrop: AllowDropType
}

export default function useVisibilityTree(
  initialOrdering: TreeNode[],
  initialVisibleKeys: TreeNodeKey[],
  nonDraggableKeys: TreeNodeKey[],
  handleEdited: () => void
): VisibilityResult {
  const [treeData, setTreeData] = useState<TreeNode[]>(initialOrdering)
  const [checkedIds, setCheckedIds] =
    useState<TreeNodeKey[]>(initialVisibleKeys)

  const allowDrag = useCallback(
    (node: DataNode) => {
      return !nonDraggableKeys.includes(String(node.key))
    },
    [nonDraggableKeys]
  )

  const onDrop: TreeProps<TreeNode>['onDrop'] = useCallback(
    (info: OnDropInfo) => {
      const data = dragAndDrop(treeData, info)
      setTreeData(data)
      handleEdited()
    },
    [treeData, handleEdited]
  )

  const onCheck = useCallback(
    (
      checkedKeys:
      | React.Key[]
      | { checked: React.Key[], halfChecked: React.Key[] },
      info: CheckInfo<TreeNode>
    ): void => {
      if (Array.isArray(checkedKeys)) {
        setCheckedIds(checkedKeys as string[])
      } else {
        setCheckedIds(checkedKeys.checked as string[])
      }
      handleEdited()
    },
    [handleEdited]
  )

  return {
    treeData,
    setTreeData,
    checkedIds,
    setCheckedIds,
    onDrop,
    onCheck,
    allowDrag,
    allowDrop
  }
}

function allowDrop(options: AllowDropOptions<TreeNode>): boolean {
  const { dragNode, dropNode, dropPosition } = options

  // allow dropping child onto another child of the same parent
  if (
    dragNode.parent != null &&
    dropNode.parent != null &&
    dropNode.parent.key === dragNode.parent.key
  ) {
    return true
  }

  // allow droping child onto it's parent
  if (dragNode.parent != null && dropNode.key === dragNode.parent.key) {
    return true
  }

  // allow dropping top level node before/after another top level node
  // dropPosition: 1 is after the drop node, -1 is before
  if (
    dragNode.parent == null &&
    dropNode.parent == null &&
    (dropPosition === -1 || dropPosition === 1)
  ) {
    return true
  }

  return false
}

/**
 * Reorders the tree nodes according to info.
 * @returns new reordered tree
 * @param originalTree - the nodes in original tree
 * @param info - drag and drop information
 */
function dragAndDrop(originalTree: TreeNode[], info: OnDropInfo): TreeNode[] {
  const dropKey = info.node.key
  const dragKey = info.dragNode.key
  const dropPos = info.node.pos.split('-')
  const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

  const data = [...originalTree]
  let dragObj2: TreeNode | undefined

  // remove drag node from tree and remember it
  loop(data, dragKey, (item, index, arr) => {
    // remove drag node
    arr.splice(index, 1)
    // remember drag node
    dragObj2 = item
  })

  if (dragObj2 == null) {
    return data
  }

  // remove undefined from dragNode type
  const dragObj: TreeNode = dragObj2

  if (dropPosition === 0) {
    // Drop on the content
    loop(data, dropKey, (item) => {
      // where to insert to the end
      item.children?.unshift(dragObj)
    })
  } else {
    // Drop on the gap (insert before or insert after)
    let ar: TreeNode[] = []
    let i: number = 0
    loop(data, dropKey, (item, index, arr) => {
      ar = arr
      i = index
    })

    if (ar.length > 0 && i < ar.length) {
      if (dropPosition === -1) {
        // before
        ar.splice(i, 0, dragObj)
      } else {
        // after
        ar.splice(i + 1, 0, dragObj)
      }
    }
  }

  return data
}

/**
 * Find node with the specified key in the tree and invoke callback
 * @param tree - array of elements that potentially have
 * children array inside each one of elements recursively
 * @param key - element key to be found
 * @param callback - function that is called when the node is found:
 * parameters include: the tree array or children array that the node was found in,
 * index in that array,
 * the node itself
 */
function loop(
  tree: TreeNode[],
  key: React.Key,
  callback: (node: TreeNode, index: number, arr: TreeNode[]) => void
): void {
  tree.forEach((item, index, arr) => {
    if (item.key === key) {
      callback(item, index, arr)
      return
    }
    if (item.children != null) {
      loop(item.children, key, callback)
    }
  })
}
